import React, { Component } from "react";
import propTypes from "prop-types";
import withStyles from "react-jss";
import classNames from "classnames";
import { Map } from "immutable";

const Styles = theme => ({
  Root: {
    marginBottom: 20
  },
  RootLoading: {
    position: "relative"
  },
  Placeholder: {
    backgroundColor: "#fff"
  },
  Placeholder_classic: {
    padding: "1rem"
  },
  PlaceholderLoading: {
    opacity: "0.4"
  },
  Loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "#fff"
  }
});

class ContentEditor extends Component {
  state = {
    loaded: false
  };

  render() {
    const { type, content, classes, init, ...props } = this.props;
    const { loaded } = this.state;
    return (
      <div className={classes.Root}>
        {(!init || !loaded) && (
          <div
            className={classNames(
              classes.Placeholder,
              classes[`Placeholder_${type}`]
            )}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {init && <Editor {...props} content={content} onLoaded={this.load} />}
      </div>
    );
  }

  load = () => {
    this.setState({
      loaded: true
    });
    this.props.onLoaded && this.props.onLoaded();
  };

  static defaultProps = {
    init: false,
    type: "classic",
    data: ""
  };
  static propTypes = {
    init: propTypes.bool.isRequired,
    type: propTypes.oneOf(["inline", "classic"]).isRequired,
    data: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
    classes: propTypes.object.isRequired
  };
}

export default withStyles(Styles)(ContentEditor);

class Editor extends Component {
  static Editor;
  static EditorTypes = {};
  state = {
    loaded: !!Editor.Editor
  };
  componentDidMount() {
    this.load();
  }

  async load() {
    if (Editor.Editor) {
      this.loaded();
      return null;
    }
    const [
      { default: e },
      { default: inline }
      //{ default: classic }
    ] = await Promise.all([
      import("@ckeditor/ckeditor5-react"),
      import("@bovieran/ckeditor5-editor")
      // import("@ckeditor/ckeditor5-build-classic")
    ]);
    Editor.Editor = e;
    Editor.EditorTypes = {
      inline,
      classic: inline
    };

    this.loaded();
  }

  loaded() {
    this.setState({
      loaded: true
    });
    this.props.onLoaded && this.props.onLoaded();
  }
  render() {
    const { type, content, loaded, onLoaded, ...props } = this.props;
    if (!this.state.loaded) return null;
    return (
      <Editor.Editor
        {...props}
        style={{
          margin: 0,
          padding: 0
        }}
        config={{
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph"
              },
              {
                model: "paragraph",
                title: "Preamble",
                class: "preamble"
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1"
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2"
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3"
              }
            ]
          }
        }}
        editor={Editor.EditorTypes[type]}
        data={content}
      />
    );
  }
  static defaultProps = {
    type: "classic",
    data: ""
  };
  static propTypes = {
    type: propTypes.oneOf(["inline", "classic"]).isRequired,
    data: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired
  };
}
export { Editor };
