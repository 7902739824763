import React, { createContext, useState, useEffect } from 'react';
import Api from "../WPAPI";
import { Config } from "../config";

const User = createContext(null);

function useUser(id) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem(Config.AUTH_TOKEN);
    if (token) {
      Api.setHeaders("Authorization", `Bearer ${token}`);
    }
    (id ? Api.users().id(id) : Api.users().me()).then(setUser).catch(error => {
      setUser(false);
      setError(error)
    })
  }, [id])
  return { user, error }
}

function Provider({ children }) {
  const { user } = useUser();
  return <User.Provider value={user} children={children} />;
}

export default {
  Context: User,
  Provider,
  Consumer: User.Consumer
}
