import React from "react";
import { Link, Redirect } from "react-router-dom";
import Header from "../partials/Header";
import PostsList from "../partials/PostsList";
import Menu from "../components/Menu";
import Panel from "../components/Panel";
import Button from "../components/Button";
import { Logout } from "../utils";
import { usePostTypes, usePosts } from "../utils/hooks";

export default function Posts({ match, location }) {
  const { posts, error } = usePosts(match.params.type);
  const { types, error: typesError } = usePostTypes();
  if (error || typesError) {
    Logout();
    return <Redirect
      to={{
        pathname: `/login/`,
        search: `?redirect=${location.pathname}`
      }}
    />
  }
  return (
    <>
      <Menu />
      <Panel>
        {error ? (
          <h1>No posts found</h1>
        ) : !posts || !types ? (
          <h1>Loading</h1>
        ) : (
          <>
            <Header title={types[match.params.type].name}>
              <Link to={`/post/${types[match.params.type].rest_base}/new/`}>
                <Button>Create new</Button>
              </Link>
            </Header>
            <PostsList type={types[match.params.type]} posts={posts} />
          </>
        )}
      </Panel>
    </>
  );
}
