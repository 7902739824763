import React, { useState, useEffect } from "react";
import { App as Preview, PageBuilder } from "@bovieran/app";
import WPAJAX from "@businessandemotions/wpajax";
import { Editor as TextEditor } from "../field-types/ContentEditor";
import WordPress from "../WPAPI";
import { ajax_url } from "../config";

function PageBuilderInit() {
  Preview.WordPress = PageBuilder.WordPress = WordPress;
  Preview.API.media = id => WordPress.media().id(id);
  Preview.API.ajax = new WPAJAX({ endpoint: ajax_url });
  Preview.init();
  PageBuilder.init();
}

export default function Editor() {
  const [preview, setPreview] = useState(false);
  const [post, setPost] = useState(false);
  function postMessage(data) {
    window.parent.postMessage({
      ...data,
      lemonade: true
    });
  }
  function onPostMessage({ data }) {
    if (!data || !data.lemonade || origin !== window.location.origin)
      return;
      
    switch (data.action) {
      case "postChange":
        setPost(data.post);
        break;
      case "preview":
        if (data.preview) {
          Preview.getInitialProps({ post: data.post }).then(r => {
            setPreview(r)
            console.log(r);
          });
        } else {
          setPreview(false);
        }
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    PageBuilderInit();
    window.addEventListener("message", onPostMessage);
    postMessage({ action: "ready" });
    return () => window.removeEventListener("message", onPostMessage);
  }, [])
  if (!post) {
    return null;
  }
  if (preview) {
    return <Preview {...preview} />;
  }
  return (
    <PageBuilder
      {...{
        post,
        renderTextEditor: props => {
          return <TextEditor {...props} />;
        }
      }}
      onChange={field => value => {
        postMessage({
          lemonade: true,
          action: "postChange",
          field,
          value
        })
      }}
    />
  );
}
