import React, { Component } from "react";
import withStyles from "react-jss";
import Panel from "../components/Panel";

const Styles = theme => ({
  Sidebar: {
    backgroundImage: "inherit",
    backgroundSize: "cover"
  },
  SidebarInner: {
    position: "fixed",
    top: "2rem",
    right: "2rem",
    bottom: "2rem"
  },
  SidebarScroll: {
    position: "relative",
    maxHeight: "100%",
    overflow: "auto"
  }
});

class Sidebar extends Component {
  render() {
    const { width, classes, children } = this.props;
    return (
      <div
        className={classes.Sidebar}
        style={{
          paddingLeft: width + 20
        }}
      >
        <Panel className={classes.SidebarInner} style={{ width }}>
          <div className={classes.SidebarScroll}>{children}</div>
        </Panel>
      </div>
    );
  }
}

export default withStyles(Styles)(Sidebar);
