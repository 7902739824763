export default {
  colors: {
    primary: "#ff0000",
    secondary: "#",
    accent: "#",
    light: "#fff",
    dark: "#272323",
    background: "#fff",
    text: "#272323",
    error: "#ef1c63",
    success: "#45f999"
  },
  typography: {
    body: {
      fontFamily: "'Roboto', sans-serif",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "1.6rem"
    },
    headings: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: "600",
      fontStyle: "normal"
    }
  }
};
