import React, { Component } from "react";
import Layout from "../partials/Layout";

import WordPress from "../WPAPI";

class Preview extends Component {
  static async getInitialProps({ query: { id, type, wpnonce } }) {
    const post = await WordPress[type]()
      .id(id)
      .auth({ nonce: wpnonce });
    return { post };
  }
  render() {
    const { post } = this.props;
    if (post && post.code && post.code === "rest_cookie_invalid_nonce") {
      return null;
    }

    return (
      <Layout>
        <h1>{post ? post.title.rendered : ""}</h1>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: post ? post.content.rendered : ""
          }}
        />
      </Layout>
    );
  }
}

export default Preview;
