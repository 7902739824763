/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import Panel from "./Panel";
import { Logout } from "../utils";
import { useUser } from "../utils/hooks";
import logo from "../static/images/logo.png";

const width = 115;
const styles = createUseStyles(theme => ({
  Root: {
    paddingLeft: ({ width }) => width,
    marginRight: 20,
    backgroundImage: "inherit",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  },
  Panel: {
    width: ({ width }) => width,
    position: "fixed",
    top: 20,
    left: 20,
    bottom: 20,
    padding: 15
  },
  Link: {
    extend: theme.typography.headings,
    color: theme.colors.light,
    fontSize: 24,
    display: "block",
    marginBottom: 15,
    textDecoration: "none"
  },
  LogoLink: {
    backgroundColor: theme.colors.light,
    borderRadius: "50%",
    padding: 15
  },
  Logo: {
    width: "100%",
    verticalAlign: "middle"
  },
  Logout: {
    border: "none",
    backgroundColor: "#fff",
    borderRadius: 3,
    display: "block",
    width: "100%",
    padding: 5,
    fontWeight: "bold"
  }
}));

export default function Menu() {
  const classes = styles({
    width
  })
  const user = useUser();
  const history = useHistory();
  if (!user) return null;
  return (
      <div className={classes.Root}>
        <Panel className={classes.Panel}>
          <Link to="/" className={classNames(classes.Link, classes.LogoLink)}>
            <img src={logo} alt="" className={classes.Logo} />
          </Link>
          <Link to="/posts/page/" className={classes.Link}>
            Pages
          </Link>
          {/* <Link to="/posts/post/" className={classes.Link}>
            Posts
          </Link> */}
          {/* <Link to="/posts/project/" className={classes.Link}>
            Projects
          </Link> */}
          <button
            type="button"
            className={classes.Logout}
            onClick={() => Logout(history)}
          >
            Logout
          </button>
        </Panel>
      </div>
  );
}
