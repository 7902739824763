import React, { useState } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "react-jss";

import Themes from "./themes";
import Snackbar from "./contexts/Snackbar";

import Layout from "./partials/Layout";
import Admin from "./partials/Admin";
import Dashboard from "./pages/Dashboard";
import Posts from "./pages/Posts";
import Post from "./pages/Post";
import Preview from "./pages/Preview";
import Login from "./pages/Login";
import Editor from "./pages/Editor";
import { homepage } from "../package.json";

function App() {
  const [theme] = useState('Default');
  return (
    <ThemeProvider theme={Themes[theme]}>
      <Snackbar.Provider>
        <Router basename={process.NODE_ENV !== "development" ? new URL(homepage).pathname : undefined}>
          {/* <Route path="/" exact component={Layout(Admin(Dashboard))} /> */}
          <Route path="/" exact render={() => <Redirect to="/posts/page/" />} />
          <Route path="/posts/:type/" component={Layout(Admin(Posts))} />
          <Route path="/post/:type/:id/" component={Layout(Admin(Post))} />
          <Route path="/preview/:type/:id/:nonce" component={Layout(Admin(Preview))} />
          <Route path="/login/" component={Layout(Login)} />
          <Route path="/editor/" exact component={Admin(Editor)} />
        </Router>
      </Snackbar.Provider>
    </ThemeProvider>
  );
}

export default App;
