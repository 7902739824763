import React from "react";
import withStyles from "react-jss";

const Styles = theme => ({
  Title: {
    display: "inline-block",
    color: theme.colors.light,
    marginTop: 0,
    marginRight: "2rem"
  }
});

export default withStyles(Styles)(({ title, classes, children }) => (
  <header>
    <h1 className={classes.Title}>{title}</h1>
    {children}
  </header>
));
