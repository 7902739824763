import React from 'react'
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" }
  },
  Spinner: {
    border: size => `${Math.round(1.6 * size)}px solid #f3f3f3`,
    borderTop: size => `${Math.round(1.6 * size)}px solid #3498db`,
    borderRadius: '50%',
    width: size => 10 * size,
    height: size => 10 * size,
    animationName: '$spin',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite'
  }
})

export default function Spinner({ size = 10 }) {
  const classes = styles(size);
  return <div className={classes.Spinner} />;
}