import React from "react";
import { Redirect } from "react-router-dom";
import MediaGallery from "../contexts/MediaGallery";
import Loader from "./Loader";
import User from "../contexts/User";
import { Logout } from "../utils";
import { useUser } from "../utils/hooks";

export function Admin({ children, location }) {
  const user = useUser();
  if (user === null) return <Loader in={true} />;
  if (user === false) {
    Logout();
    return <Redirect to={`/login/?redirect=${location.pathname}${location.search}`} />
  }
  return (
    <MediaGallery.Provider>
      {children}
    </MediaGallery.Provider>
  );
}

export default function(View) {
  return props => (
    <User.Provider>
      <Admin {...props} children={<View {...props} />}/>
    </User.Provider>
  );
}
