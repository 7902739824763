const WPAPI = require("wpapi");
const Config = require("./config");

const WordPress = new WPAPI({ endpoint: Config.rest_url });
WordPress.projects = WordPress.registerRoute("wp/v2", "/projects");
WordPress.any = WordPress.registerRoute("wp/v2", "/any/(?P<id>\\d+)");
WordPress.media_categories = WordPress.registerRoute(
  "wp/v2",
  "media_categories"
);
WordPress.lemonade_post_type_settings = WordPress.registerRoute(
  "lemonade/v1",
  "/settings"
);
WordPress.menus = WordPress.registerRoute(
  "menus/v1",
  "/menus/(?P<location>[\\w\\d\\_\\-]*)"
);
WordPress.customizer = WordPress.registerRoute("lemonade/v1", "/customizer");
export default WordPress;
