import React from "react";
import { createUseStyles } from "react-jss";
import splash from "../static/images/splash.jpg";

const styles = createUseStyles(theme => ({
  Root: {
    backgroundImage: `url(${splash})`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    position: "relative",
    zIndex: 2,
    padding: 20,
    minHeight: "100vh",
    overflow: "hidden",
    display: "flex",
    alignItems: "flex-start"
  },
  "@global": {
    "*, *:before, *:after": {
      boxSizing: "border-box"
    },
    html: {
      fontSize: "62.5%"
    },
    body: {
      padding: 0,
      margin: 0,
      extend: theme.typography.body,
      color: theme.colors.text
    },
    "h1,h2,h3,h4": theme.typography.headings
  }
}));


function Layout({ children }) {
  const classes = styles();
  return (
    <div
      className={classes.Root}
      children={children}
    />
  )
}
export default function(View) {
  return props => <Layout children={<View {...props} />}/>;
}