import { useState, useEffect, useContext, useRef } from 'react'
import _throttle from "lodash.throttle";
import Api from "../WPAPI";
import MediaGallery from "../contexts/MediaGallery"
import Snackbar from "../contexts/Snackbar";
import Post from "../contexts/Post";
import User from "../contexts/User";

export function usePostTypes() {
  const [state, setState] = useState({
    types: null,
    error: false
  });
  useEffect(() => {
    const promise = {};
    new Promise((resolve, reject) => {
      Object.assign(promise, { resolve, reject });
      return Api.types().embed().then(resolve).catch(reject)
    }).then(types => {
      setState(state => Object.assign({}, state, { types }));
    }).catch(error => {
      if (process.env.NODE_ENV === "development") {
        console.warn(error);
      }
      promise.reject = null;
      setState(state => Object.assign({}, state, { error }))
    });
    return () => promise && typeof promise.reject === "function" && promise.reject("Unmounted");
  }, [])
  return state;
}
export function usePosts(type) {
  const [state, setState] = useState({
    posts: null,
    error: false
  });
  const { types } = usePostTypes();
  useEffect(() => {
    const promise = {};
    if (types === null || !(type in types)) return;
    new Promise((resolve, reject) => {
      Object.assign(promise, { resolve, reject });
      return Api[types[type].rest_base]().embed().then(resolve).catch(reject)
    }).then(posts => {
      setState(state => Object.assign({}, state, { posts }));
    }).catch(error => {
      if (process.env.NODE_ENV === "development") {
        console.warn(error);
      }
      promise.reject = null;
      setState(state => Object.assign({}, state, { error }))
    });
    return () => promise && typeof promise.reject === "function" && promise.reject("Unmounted");
  }, [types, type])
  return state;
}

/* export function getPost(type, id) {
  const [state, setState] = useState({
    post: null,
    error: false
  });
  const { types } = usePostTypes();
  useEffect(() => {
    const promise = {};
    if (types === null || !(type in types)) return;
    new Promise((resolve, reject) => {
      Object.assign(promise, { resolve, reject });
      return Api[types[type].rest_base]().id(id).embed().then(resolve).catch(reject)
    }).then(post => {
      setState(state => Object.assign({}, state, { post }));
    }).catch(error => {
      if (process.env.NODE_ENV === "development") {
        console.warn(error);
      }
      promise.reject = null;
      setState(state => Object.assign({}, state, { error }))
    });
    return () => promise && typeof promise.reject === "function" && promise.reject("Unmounted");
  }, [types, type, id])
  return state;
} */

export function usePostTypeSettings() {
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    Api.lemonade_post_type_settings().then(setSettings);
  }, []);
  return settings;
}

export function usePostCache(defaultValue = []) {
  const [cache, setCache] = useState(defaultValue);
  return [cache, (...toAdd) => {
    if (toAdd.length === 1 && toAdd instanceof Array) {
      toAdd = toAdd[0].slice();
    }
    const newCache = cache.slice();
    newCache.push.apply(newCache, toAdd.filter(post => !newCache.find(p => post.id === p.id)));
    newCache.sort((a,b) => new Date(a.date) >= new Date(b.date) ? a : b)
    setCache(newCache);
  }]
}

export function useMediaCategories() {
  const [mediaCategories, setMediaCategories] = useState(null);
  useEffect(() => {
    Api.media_categories().then(setMediaCategories)
  }, []);
  return mediaCategories;
}

export function useInfiniteScroll({ active, scrollListener = true, scrollThrottle = 200, useInterval }, onLoad) {
  const container = useRef(window);
  const marker = useRef();
  const [visible, setVisible] = useState(false);
  function checkVisible() {
    if (!container.current || !marker.current) return;
    if (container.current.scrollTop + container.current.clientHeight >= marker.current.offsetTop) {
      if (!visible) {
        setVisible(true);
      }
      typeof onLoad === "function" && onLoad();
    } else {
      visible && setVisible(false);
    }
  }
  useEffect(() => {
    if (!active || !container.current || !marker.current) return;
    checkVisible();
    const onScroll = _throttle(checkVisible, scrollThrottle)
    scrollListener && container.current.addEventListener("scroll", onScroll);
    var interval;
    if (useInterval) {
      interval = setInterval(checkVisible, typeof useInterval === "number" ? useInterval : 250);
    }
    return () => {
      interval && clearInterval(interval);
      scrollListener && container.current && container.current.removeEventListener("scroll", onScroll);
    }
  }, [active, scrollListener, scrollThrottle, useInterval, container, marker]);
  return { visible, container, marker };
}

export function useMediaGallery() {
  return useContext(MediaGallery.Context)
}

export function useSnackbar() {
  return useContext(Snackbar.Context);
}

export function usePost() {
  return useContext(Post.Context);
}

export function useUser() {
  return useContext(User.Context);
}