import React from "react";
import { Transition } from "react-transition-group";

export default function Animate(props) {
  const {
    comp = "div",
    in: inProp,
    duration,
    easing,
    children,
    style,
    animationStyles,
    mountOnEnter,
    unmountOnExit,
    ...rest
  } = props;
  const animation = animationStyles(props);
  return (
    <Transition
      in={inProp}
      timeout={duration}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      {state =>
        React.createElement(
          comp,
          {
            ...rest,
            style: Object.assign({}, style, animation.base, state ? animation[state] : {})
          },
          children
        )
      }
    </Transition>
  );
}
Animate.defaultProps = {
  duration: 300,
  easing: "ease-in-out",
  style: {}
};
