import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import Title from "../field-types/Title";
import MediaSelect from "../field-types/MediaSelect";

import Menu from "../components/Menu";
import Panel from "../components/Panel";
import Button from "../components/Button";
import Sidebar from "../partials/Sidebar";
import PageBuilder from "../partials/PageBuilder";
import Spinner from "../partials/Spinner";
import ContentEditor from "../field-types/ContentEditor";

import { usePostTypeSettings, useMediaGallery, useSnackbar, usePost } from "../utils/hooks";
import PostContext from "../contexts/Post";

const styles = createUseStyles({
  Editor: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 40px)",
    fallbacks: {
      height: "100vh"
    }
  },
})

export default function View(props) {
  const { match: { params: { id, type } } } = props;
  return (
    <PostContext.Provider {...{ id, type, ...props }}>
      <Post {...props} />
    </PostContext.Provider>
  )
}
function Post({ type }) {

  const classes = styles();
  const { error, post, postHasChanged, updating, updatePost, postChanged } = usePost();
  const settings = usePostTypeSettings();
  const mediaGallery = useMediaGallery();
  const { addSnack } = useSnackbar();

  const isPageBuilder =
    settings &&
    settings.editor &&
    post &&
    post.raw &&
    (settings.editor[type] || settings.editor.indexOf(post.raw.type) >= 0);
  const onPostChange = field => async value => {
    postChanged(field)(value);
    // messageIframe({ action: "postChange", post: post.rendered });
  };
  if (!post) {
    return <Spinner />
  }
  return (
    <>
      <Menu />
      {!isPageBuilder ? (
        <Panel>
          {error && <h1>{error || "No post found"}</h1>}
          {!error && !post && <h1>Loading</h1>}
          {!error && post && post.rendered && (
            <>
              <Title
                content={post.rendered.title}
                onChange={onPostChange("title")}
              />
              <ContentEditor
                init
                content={post.rendered.content}
                onChange={(event, editor) => {
                  onPostChange("content")(editor.getData());
                }}
              />
            </>
          )}
        </Panel>
      ) : (
        <PageBuilder className={classes.Editor} />
      )}
      <Sidebar width={200}>
        {!error && post && post.rendered ? (
          <>
            <MediaSelect
              media={post.rendered.featured_media}
              openMediaUploadModal={() => {
                mediaGallery.open().then(image => {
                  onPostChange("featured_media")(image.id);
                })
              }}
            />
            <Button disabled={!postHasChanged || updating} onClick={() => updatePost().then(() => addSnack({
              type: "success",
              message: "Post is updated successfully",
            }))}>
              Update
            </Button>
          </>
        ) : null}
      </Sidebar>
    </>
  )
}
