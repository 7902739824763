import React from "react";
import Animate from "./index";

const styles = ({ duration, easing }) => ({
  base: {
    opacity: 0,
    transition: `opacity ${duration}ms ${easing}`
  },
  entring: {
    opacity: 1
  },
  entered: {
    opacity: 1
  },
  exiting: {
    opacity: 0
  },
  exited: {
    opacity: 0
  }
});

export default function Fade(props) {
  return <Animate {...props} animationStyles={styles} />;
}
