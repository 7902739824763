import React from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { Units } from "../utils";

const styles = createUseStyles(theme => ({
  Label: {
    display: 'inline-block',
    overflow: "hidden",
    padding: ['.7em', '1.4em', '0.9em'],
    marginBottom: '.3em',
    border: 'none',
    borderRadius: '.2em',
    backgroundColor: "#ddd",
    fontSize: "11px",
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    boxShadow: `inset 0 -0.6em 0 -0.35em ${Units.rgba(
      theme.colors.dark,
      0.17,
    )}`
  },
  Progress: {
    backgroundImage: progress => `linear-gradient(to right, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.25) ${Math.min(progress, 98)}%, rgba(0,0,0,0) ${Math.min(progress + 2, 99)}%, rgba(0,0,0,0) 100%)`
  },
  Input: {
    opacity: 0,
    position: "absolute",
    zIndex: -1,
    width: 0.1,
    height: 0.1,
    overflow: "hidden",
  },
  Icon: {
    width: "1em",
    height: "1em",
    verticalAlign: "middle",
    fill: "currentColor",
    marginTop: "-0.25em",
    marginRight: "0.25em"
  }
}));

export default function InputFile({ disabled, icon = true, progress = false, children, onChange }) {
  const classes = styles(progress);
  return (
    <label className={cx(classes.Label, progress !== false && classes.Progress)}>
      <input
        type="file"
        className={classes.Input}
        multiple
        onChange={event => {
          if (!event.target.files.length) return;

          if (typeof onChange === "function")
            onChange([...event.target.files]);
        }}
        disabled={disabled}
      />
      {icon && <FontAwesomeIcon icon={progress !== false ? faSpinnerThird : faUpload} spin={progress !== false} />}
      {children}
    </label>
  );
}

