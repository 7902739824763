import React from "react";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  Header: {
    display: "flex",
    backgroundColor: "#fff",
    borderBottom: "2px solid rgba(0,0,0,0.1)"
  },
  Left: {
    display: "flex",
    width: "100%"
  },
  Right: {
    display: "flex",
  }
});

function EditorHeader({ buttons, children }) {
  const classes = styles();
  return (
    <div>
      <header className={classes.Header}>
        <div className={classes.Left}>
          {children}
        </div>
        <div className={classes.Right}>
          {buttons}
        </div>
      </header>
    </div>
  );
}
export default EditorHeader;
