import React, { useState, useEffect, useContext, useRef } from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import { Checkboard } from "react-color/lib/components/common"

import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import InputFile from "../components/InputFile";

import WordPress from "../WPAPI";
import { useMediaCategories, usePostCache, useSnackbar, useInfiniteScroll } from "../utils/hooks";
import Loader from "../partials/Loader";

export const MediaGalleryContext = React.createContext({});

const styles = createUseStyles(theme => ({
  Body: {
    overflowY: "hidden"
  },
  Container: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
  },
  Backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  Content: {
    position: "relative",
    zIndex: 2,
    width: 1440,
    maxWidth: "90%",
    maxHeight: "80vh",
    backgroundColor: "#fff",
    padding: 30,
    margin: ["10vh", "auto"],
    paddingLeft: 260 + 30,
    paddingBottom: 35 + 30 + 30,
    display: "flex",
  },
  Sidebar: {
    position: "absolute",
    top: 30,
    bottom: 30 + 35 + 30,
    left: 0,
    padding: 30,
    width: 260,
    borderRight: "1px solid #eee"
  },
  ScrollContainer: {
    overflowY: "auto",
    position: "relative"
  },
  ActionBar: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    padding: 30,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#fff",
  },
  List: {
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  MediaListItem: {
    display: "inline-block",
    width: 150,
    maxWidth: "50%",
    margin: [0, 10],
    cursor: "pointer",
  },
  Media: {
    border: "3px solid #eee",
    position: "relative",
  },
  MediaSelected: {
    borderColor: theme.colors.primary
  },
  Image: {
    position: "relative",
    zIndex: 2,
    display: "inline-block",
    width: "100%",
    verticalAlign: "top"
  },
  ImageTitle: {
    marginTop: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  InfiniteScrollMarker: {
    width: "100%",
    height: 1
  },
}))

function getImagesSize(image, size) {
  return (
    (image.media_details &&
      image.media_details.sizes &&
      size in image.media_details.sizes &&
      image.media_details.sizes[size].source_url) ||
    image.source_url
  );
}

const defaultOptions = {
  multiple: false
};
const defaultState = {
  uploadProgress: false,
  open: false,
  loadMore: false,
  fetching: false,
  selectedCategories: [],
  selectedMedia: [],
  options: defaultOptions
}
const settings = {
  perPage: 18
};

class MediaContextValue {
  state = defaultState;

  constructor(state, updateState) {
    this.state = Object.assign({}, this.state, state);
    this.__DO_NOT_USE__UPDATE_STATE = updateState;
  }
  setSelectedCategories = (...categories) => {
    if (categories.length === 1 && categories[0] instanceof Array) categories = categories[0];
    this.__DO_NOT_USE__UPDATE_STATE({ selectedCategories: categories })
  };
  setSelectedMedia = (...media) => {
    if (media.length === 1 && media[0] instanceof Array) media = media[0];
    this.__DO_NOT_USE__UPDATE_STATE({ selectedMedia: media });
  };
  open = (_options = {}) => {
    let newOptions = Object.assign({}, defaultOptions);
    Object.keys(defaultOptions).map(optionName => {
      if (optionName in _options) {
        newOptions[optionName] = _options[optionName];
      }
    });
    return new Promise((resolve, reject) => {
      this.__DO_NOT_USE__UPDATE_STATE({
        open: { resolve, reject },
        options: newOptions
      });
    });
  };
  close = (reason = "User closed.") => {
    if (this.state.open.reject) {
      this.state.open.reject(reason);
      this.__DO_NOT_USE__UPDATE_STATE({ open: false })
    }
  }
}

function Provider({ children }) {  
  const classes = styles();
  const [state, setState] = useState(defaultState);
  function updateState(newState) {
    setState(Object.assign({}, state, newState));
  }
  const [page, setPage] = useState(1);
  /* const [uploadProgress, setUploadProgress] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false)
  const [isFetching, setIsFetching] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [options, setOptions] = useState(defaultOptions); */

  const mediaCategories = useMediaCategories();
  const [media, setMedia] = usePostCache()
  const { addSnack } = useSnackbar();
  const ContextValue = useRef(new MediaContextValue(state, updateState))

  function getNext() {
    if (!state.fetching && page > 0 && state.open) {
      updateState({ fetching: true });
      WordPress.media().page(page).perPage(settings.perPage).then(result => {
        updateState({
          fetching: false
        })
        setMedia(...result);
        if (result.length < settings.perPage) {
          setPage(0);
        } else {
          setPage(page + 1);
        }
      }).catch(error => {
        updateState({
          fetching: false
        })
        if (error.code === "rest_post_invalid_page_number")
          setPage(0);
      })
    }
  }
  const { open = false, selectedMedia = [], selectedCategories = [], fetching = false, options = {}, uploadProgress = false } = state;
  const filteredMedia = selectedCategories.length > 0
    ? media.filter(post => post.media_categories.find(cat => selectedCategories.find(cat2 => cat.id === cat2.id)))
    : media;
  const InfiniteScroll = useInfiniteScroll({
    active: open && page > 0 && !fetching,
    useInterval: true,
  }, getNext);

  useEffect(() => {
    if (!state) return;
    if (state.open) {
      document.body.classList.add(classes.Body);
    }
    return () => {
      document.body.classList.remove(classes.Body)
    };
  }, [state.open])

  useEffect(() => {
    ContextValue.current.state = Object.assign({}, ContextValue.current.state, state);
  }, [state])
  
  if (!state) return null;

  function selectMedia(id) {
    let index = selectedMedia.indexOf(id);
    let newSelectedMedia = selectedMedia.slice();
    if (index >= 0) {
      newSelectedMedia.splice(index, 1);
    } else {
      if (options.multiple) {
        newSelectedMedia.push(id);
      } else {
        newSelectedMedia = [id];
      }
    }
    updateState({ selectedMedia: newSelectedMedia });
  }
  function reset() {
    updateState(defaultState);
  }
  function resolve(media) {
    if (open.resolve) {
      open.resolve(options.multiple ? media : media[0]);
      reset();
    }
  }
  function reject(reason) {
    if (open.reject) {
      open.reject(reason);
      reset();
    }
  }
  
  return (
    <MediaGalleryContext.Provider value={ContextValue.current}>
      {children}
      {open && (
        <div className={classes.Container}>
          <div className={classes.Backdrop} onClick={() => reject("User cancel (Backdrop).")} />
          <div className={classes.Content}>
            <div className={classes.Sidebar}>
              {mediaCategories && (
                <>
                  <p>Categories</p>
                  <ul className={classes.List}>
                    {mediaCategories.map(category => {
                      return (
                        <li key={category.id}>
                          <Checkbox
                            checked={selectedCategories.indexOf(category.id) >= 0}
                            onChange={checked => {
                              const newSelectedCategories = selectedCategories.slice();
                              if (checked) {
                                newSelectedCategories.push(category.id);
                              } else if (newSelectedCategories.indexOf(category.id) >= 0) {
                                newSelectedCategories.splice(newSelectedCategories.indexOf(category.id), 1);
                              }
                              updateState({ selectedCategories: newSelectedCategories });
                            }}
                            children={category.name}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              <InputFile
                progress={uploadProgress}
                disabled={uploadProgress !== false}
                onChange={files => {
                  if (uploadProgress !== false) return;
                  updateState({ uploadProgress: 0 });
                  var fileSizes = files.map(f => f.size);
                  const Upload = (index = 0, f = []) => {
                    var file = files[index];
                    WordPress.media().file(file).create({
                      title: file.name,
                      media_categories: selectedCategories
                    }).then(result => {
                      f.push(result);
                      Finally(index, f);
                    }).catch(error => {
                      console.log("Error: ", error);
                      addSnack({
                        key: index,
                        type: "error",
                        message: `Failed to upload "${file.name}"`
                      })
                      Finally(index, f);
                    })
                  };
                  const Finally = (index, result) => {
                    const uploadProgress = Math.round((fileSizes.slice(0, index).reduce((val, size) => val + size, 0) / fileSizes.reduce((val, size) => val + size, 0)) * 100);
                    updateState({ uploadProgress });
                    if (index === files.length - 1) {
                      addSnack({
                        key: index,
                        type: "success",
                        message: `Uploaded ${result.length} files.`
                      })
                      setMedia(...result);
                      setTimeout(() => updateState({ uploadProgress: false }), 500);
                    } else {
                      Upload(index + 1);
                    }
                  }
                  Upload();
                }}
              >
                {uploadProgress !== false ? `${uploadProgress}%` : "Upload images"}
              </InputFile>
            </div>
            <div ref={InfiniteScroll.container} className={classes.ScrollContainer}>
              <ul className={classes.List}>
                {filteredMedia.map(post => (
                    <li
                      key={post.id}
                      className={classes.MediaListItem}
                      onClick={() => selectMedia(post.id)}
                      onDoubleClick={event => {
                        if (!options.multiple) {
                          event.preventDefault();
                          event.nativeEvent.preventDefault();
                          resolve([post]);
                          return false;
                        }
                      }}
                    >
                      <div className={cx(classes.Media, selectedMedia.indexOf(post.id) >= 0 && classes.MediaSelected)}>
                        <Checkboard />
                        <img
                          className={classes.Image}
                          src={getImagesSize(post, "thumbnail")}
                          alt={post.alt_text}
                          alt={post.alt_text !== "" ? post.alt_text : post.title.rendered}
                        />
                      </div>
                      <p className={classes.ImageTitle} alt={post.title.rendered}>{post.title.rendered}</p>
                    </li>
                ))}
              </ul>
              <div ref={InfiniteScroll.marker} className={classes.InfiniteScrollMarker} />
              <Loader in={fetching} size={4} mountOnEnter unmountOnExit />
            </div>
            <div className={classes.ActionBar}>
              <Button
                disabled={selectedMedia.length === 0}
                onClick={() => resolve(selectedMedia)}>
                Use selected
              </Button>
            </div>
          </div>
        </div>
      )}
    </MediaGalleryContext.Provider>
  );
}

export default {
  Context: MediaGalleryContext,
  Provider,
  Consumer: MediaGalleryContext.Consumer
}