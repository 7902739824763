import React, { Component } from "react";
import Admin from "../partials/Admin";
import Layout from "../partials/Layout";
import Menu from "../components/Menu";

class Index extends Component {
  render() {
    return (
      <>
        <Menu />
        <div>
          <h1>Dashboard</h1>
        </div>
      </>
    );
  }
}

export default Index;
