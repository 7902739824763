import Api from "../WPAPI";
import { Config } from "../config";

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r.concat(r, g, g, b, b);
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
}

export const Units = {
  rem: u => `${u}rem`,
  percent: u => `${u}%`,
  em: u => `${u}em`,
  px: u => `${u}px`,
  vh: u => `${u}vh`,
  vw: u => `${u}vw`,
  rgba(hex, alpha) {
    alpha = alpha > 1 ? alpha / 100 : alpha;
    return `rgba(${hexToRgb(hex).join(",")},${alpha})`;
  }
};

export function Logout(history = false) {
  localStorage.removeItem(Config.AUTH_TOKEN);
  Api.setHeaders("Authorization", undefined);
  history && history.push("/login/");
}