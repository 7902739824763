import React from "react";
import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  Input: {
    fontSize: "2.8rem",
    padding: "1rem 1.5rem",
    width: "100%",
    marginBottom: 20
  }
});

export default function Title({ content, onChange, ...props }) {
  const classes = styles();
  return <input
    type="text"
    className={classes.Input}
    {...props}
    value={content}
    onChange={event => {
      onChange(event.target.value);
    }}
  />
};
