import React, { useState, useEffect } from 'react'
import { fromJS } from "immutable";
import Api from "../WPAPI";
import { Logout } from "../utils";

const Post = React.createContext(null);

const newPost = type => ({
  type,
  title: {
    raw: {},
    rendered: {}
  },
  content: {
    raw: {},
    rendered: {}
  },
  featured_media: null,
  lemonade_content: null
});

async function getPost({ id, type, history }) {
  try {
    const raw = id === "new" ? newPost(type) : await Api[type]()
      .id(id)
      .context("edit")
      .embed();
    const post = await initialPostState(raw);

    return {
      post,
      postHasChanged: false
    };
  } catch (error) {
    if (error.data && error.data.status >= 400) {
      Logout(history);
    } else {
      return {
        error
      }
    }
  }
}

function clone(obj) {
  return fromJS(obj).toJS();
}

async function getRenderedPost(post) {
  post = clone(post);
  const { featured_media } = post;
  if (featured_media && featured_media > 0) {
    let media = post._embedded['wp:featuredmedia'][0];
      if (media && media.id === featured_media) {
        post.featured_media = media;
      } else {
      post.featured_media = await Api.media().id(featured_media);
    }
  }
  return Object.assign({}, post, {
    title: post.title.raw,
    content: post.content.raw,
    lemonade_content: post.lemonade_content
  });
}

async function initialPostState(post) {
  const p = {
    raw: post,
    changed: {},
    rendered: await getRenderedPost(post)
  };
  if (post.id) {
    p.changed.id = post.id;
  }
  return p;
}

async function reRenderField(field, value, post) {
  const { changed, rendered } = post;

  switch (field) {
    case "featured_media":
      if (value !== changed.featured_media && (!rendered.featured_media || value !== rendered.featured_media.id)) {
        return value ? await Api.media().id(value) : value;
      }
      return rendered.featured_media;
    case "title":
    case "content":
      return {
        rendered: value
      };
    default:
      return value;
  }
}

function Provider({ id, type, history, children }) {
  const [state, setState] = useState({
    error: false,
    post: false,
    updating: false,
    postHasChanged: false,
  });

  function updateState(newState) {
    console.log(newState);
    setState(Object.assign({}, state, newState));
  }

  useEffect(() => {
    getPost({ id, type, history }).then(updateState).catch(error => {
      console.warn("Error: ", error)
    });
  }, [id])

  return (
    <Post.Provider value={{
      ...state,
      updatePost: async () => {
        updateState({
          updating: true
        });
        const newPost =
          await (id === "new"
            ? Api[type]().create(state.post.changed)
            : Api[type]().id(id).update(state.post.changed));

        if (newPost && id !== newPost.id) {
          history.push({
            pathname: `/post/${type}/${newPost.id}`
          });
          return;
        }
        const refreshedPostState = await initialPostState(newPost);
        updateState({
          post: refreshedPostState,
          updating: false,
          postHasChanged: false,
        });
      },
      postChanged: field => async value => {    
        const post = clone(state.post);
        post.rendered[field] = await reRenderField(field, value, state.post)
        post.changed[field] = value;
        updateState({
          post,
          postHasChanged: true
        });
      }
    }} children={children} />
  )
}
Provider.displayName = "Post.Provider";

export default {
  Context: Post,
  Provider,
  Consumer: Post.Consumer
}