import React, {Component} from "react";
import withStyles from "react-jss";

import Button from "../components/Button";

const Styles = theme => ({
  MediaSelectContainer: {
    margin: "20px 0"
  },
  MediaSelectButton: {
    width: "100%",
    margin: 0
  },
  Image: {
    width: "100%",
    marginBottom: 10
  }
});

class MediaSelect extends Component {
  render() {
    const { media, classes, openMediaUploadModal } = this.props;
    return (
      <div className={classes.MediaSelectContainer}>
        {media.source_url && (
          <>
            <img className={classes.Image}
                 src={media.source_url}
                 alt={media.alt_text}
            />
          </>
        )}
        <Button className={classes.MediaSelectButton}
                onClick={openMediaUploadModal}>Change thumbnail
        </Button>
      </div>
    );
  }
}

export default withStyles(Styles)(MediaSelect);
