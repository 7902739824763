import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from "react-jss";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faExpandAlt,
  faCompressAlt,
  faEye,
  faEyeSlash,
  faCircleNotch
} from "@fortawesome/pro-solid-svg-icons";
import EditorHeader from "../partials/EditorHeader";
import Title from "../field-types/Title";
import { useMediaGallery, useSnackbar, usePost } from "../utils/hooks";
import { homepage } from "../../package.json";

const styles = createUseStyles({
  Fullscreen: {
    position: "fixed",
    zIndex: 100,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  Title: {
    border: 'none',
    margin: 0,
    fontSize: "2.8rem",
    padding: "1rem 1.5rem",
    width: "100%"
  },
  Button: {
    cursor: "pointer",
    width: 60,
    height: 60,
    fontSize: "2rem",
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderColor: "rgba(0,0,0,0.1)",
    borderWidth: "0 0 0 2px",
  },
  FrameWrapper: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  Frame: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
})

export default function PageBuilder({ className }) {
  const classes = styles();
  const iframe = useRef();
  const [ready, setReady] = useState(false);
  const [preview, setPreview] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const { post, updating, updatePost, postChanged } = usePost();
  const mediaGallery = useMediaGallery();
  const { addSnack } = useSnackbar();

  function messageIframe(data) {
    if (!ready) return;
    try {
      iframe.current.contentWindow.postMessage(
        Object.assign({}, data, { lemonade: true })
      );
    } catch (error) {
      addSnack({
        type: "error",
        message: error.message
      })
      console.warn("PostMessage failed", error);
    }
  }
  const onPostMessage = ({ data }) => {
    if (!data || ! data.lemonade || !process.browser || origin !== window.location.origin) return;
    switch (data.action) {
      case "ready":
        setReady(true)
        break;
      case "postChange":
        postChanged(data.field)(data.value);
        break;
      case "requestImage":
        mediaGallery.open()
          .then(image => {
            messageIframe({
              action: "requestImage",
              image
            });
          })
          .catch(error => {
            messageIframe({
              action: "requestImage",
              error
            });
          });
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    window.addEventListener("message", onPostMessage);
    return () => window.removeEventListener("message", onPostMessage);
  }, []);
  useEffect(() => {
    messageIframe({
      action: "postChange",
      post: Object.assign({}, post.raw, post.changed)
    });
  }, [ready])
  if (!post) return null;
  return (
    <div className={cx(className, fullscreen && classes.Fullscreen)}>
      <EditorHeader
        buttons={
          <>
            <button
              className={classes.Button}
              type="button"
              onClick={() => setFullscreen(!fullscreen)}
            >
              <FontAwesomeIcon icon={fullscreen ? faCompressAlt : faExpandAlt} />
            </button>
            <button
              className={classes.Button}
              type="button"
              onClick={() => {
                messageIframe({ action: "preview", preview: !preview, post: Object.assign({}, post.raw, post.changed) });
                setPreview(!preview);
              }}
            >
              <FontAwesomeIcon icon={preview ? faEyeSlash : faEye} />
            </button>
            <button
              className={classes.Button}
              type="button"
              onClick={() => updatePost()}
            >
              <FontAwesomeIcon
                icon={updating ? faCircleNotch : faSave}
                spin={updating}
              />
            </button>
          </>
        }
      >
        <Title
          content={post.rendered.title}
          onChange={postChanged("title")}
          className={classes.Title}
        />
      </EditorHeader>
      <div className={classes.FrameWrapper}>
        <iframe
          ref={iframe}
          className={classes.Frame}
          frameBorder="none"
          title="Lemonade Editor"
          src={`${window.location.origin}${new URL(homepage).pathname}editor/`}
        />
      </div>
    </div>
  )
}