import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { createUseStyles } from "react-jss";
import axios from "axios";
import qs from "query-string";
import Panel from "../components/Panel";
import Button from "../components/Button";
import User from "../contexts/User";
import { useUser, useSnackbar } from "../utils/hooks";
import Api from "../WPAPI";
import { Config } from "../config";

const useStyles = createUseStyles({
  Root: {
    alignSelf: "center",
    width: 280,
    margin: "0 auto"
  },
  Title: {
    textAlign: "center",
    marginTop: 0
  },
  Input: {
    width: "100%",
    padding: [8, 15],
    marginBottom: 15
  }
});

export default function View(props) {
  return <User.Provider children={<Login {...props}/>}/>;
}

function Login({ location }) {
  const classes = useStyles();
  const { addSnack } = useSnackbar();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [didLogin, setDidLogin] = useState(false);
  const user = useUser();
  if (user || didLogin) {
    return <Redirect to={(location.search && qs.parse(location.search).redirect) || "/"} />;
  }
  return (
    <Panel className={classes.Root}>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (username === "") {
            addSnack({
              type: "warning",
              message: "Username cannot be empty!"
            })
            return;
          }
          if (password === "") {
            addSnack({
              type: "warning",
              message: "Password cannot be empty!"
            })
            return;
          }
          axios
            .post(`${Config.rest_url}/jwt-auth/v1/token`, {
              username,
              password
            })
            .then(res => {
              const {
                data: { token, user_nicename }
              } = res;
              localStorage.setItem(Config.AUTH_TOKEN, token);
              localStorage.setItem(Config.USERNAME, user_nicename);
              Api.setHeaders("Authorization", `Bearer ${token}`);
              setUsername("");
              setPassword("");
              setDidLogin(true);
              addSnack({
                type: "success",
                message: `Welcome ${user_nicename}!`
              })
            })
            .catch(err => {
              addSnack({
                type: "error",
                message: err.message || "Sorry, that username and password combination is not valid."
              })
            });
        }}
      >
        <h1 className={classes.Title}>Login</h1>
        <input
          className={classes.Input}
          value={username}
          onChange={e => setUsername(e.target.value)}
          type="text"
          placeholder="Your username"
        />
        <input
          className={classes.Input}
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          placeholder="Your password"
        />
        <Button type="submit" full>
          Login
        </Button>
      </form>
    </Panel>
  );
}

