import React, { Component } from "react";
import { Link } from "react-router-dom";
import withStyles from "react-jss";
import classNames from "classnames";

import { Units } from "../utils";

const Styles = theme => ({
  Posts: {
    width: "100%",
    borderCollapse: "collapse"
  },
  Post: {
    color: theme.colors.light,
    cursor: "pointer",
    borderBottom: `1px solid ${Units.rgba(theme.colors.light, 0.6)}`
  },
  Link: {
    color: theme.colors.light
  },
  Status: {
    display: "inline-block",
    width: "9rem",
    marginLeft: "3rem",
    "&:before": {
      content: '"•"',
      display: "inline-block",
      fontSize: "3rem",
      lineHeight: ".8",
      verticalAlign: "text-top",
      marginRight: ".5rem"
    }
  },
  PostTitle: {
    display: "inline-block"
  },
  Status_draft: {
    "&:before": {
      color: "#ccc"
    }
  },
  Status_private: {
    "&:before": {
      color: theme.colors.text
    }
  },
  Status_publish: {
    "&:before": {
      color: "green"
    }
  },
  Divider: {
    opacity: ".6"
  }
});

const StatusLabels = {
  draft: "Draft",
  private: "Private",
  publish: "Published"
};

class PostsList extends Component {
  render() {
    const { type, posts, classes } = this.props;
    return (
      <table className={classes.Posts}>
        <tbody>
          {posts.map(post => (
            <tr key={post.id} className={classes.Post}>
              <td>
                <h2 className={classes.PostTitle}>
                  <Link
                    to={`/post/${type.rest_base}/${post.id}/`}
                    className={classes.Link}
                  >
                    {post.title.rendered}
                  </Link>
                </h2>
              </td>
              <td>
                <span
                  className={classNames(
                    classes.Status,
                    classes[`Status_${post.status}`]
                  )}
                >
                  {StatusLabels[post.status]}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default withStyles(Styles)(PostsList);
