
export const rest_url = process.env.REACT_APP_CONFIG_REST_URL
  ? process.env.REACT_APP_CONFIG_REST_URL
  : window.location.origin.replace("/admin.", "/wordpress.").concat("/wp-json");

export const ajax_url = process.env.REACT_APP_CONFIG_AJAX_URL
  ? process.env.REACT_APP_CONFIG_AJAX_URL
  : window.location.origin.replace("/admin.", "/wordpress.").concat("/wp-admin/admin-ajax.php");
  
export const Config = {
  rest_url,
  ajax_url,
  AUTH_TOKEN: "auth-token",
  USERNAME: "username",
  SETTINGS: "settings"
};
