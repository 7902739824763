import React, { createContext, useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import { Map } from "immutable";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

const styles = createUseStyles(theme => {
  const snackBarHeight = "50px";
  const snackBarButtonSize = "18px";
  return {
    Root: {
      position: "fixed",
      bottom: 20,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 10000,
    },
    Snack: {
      visibility: "hidden",
      opacity: 0,
      height: snackBarHeight,
      padding: "0 20px",
      borderRadius: 6,
      backgroundColor: theme.colors.dark,
      color: theme.colors.light,
      marginTop: 10,
      transition: "opacity 200ms, margin-bottom 200ms, transform 200ms",

      "&.snack-bar-enter": {
        visibility: "visible",
        opacity: 0,
        marginBottom: -snackBarHeight - 20
      },
      "&.snack-bar-enter-done": {
        visibility: "visible",
        opacity: 1,
        marginBottom: 0,
        transform: "scale(1)"
      },
      "&.snack-bar-exit": {
        transform: "scale(0)"
      },
      "&.snack-bar-exit-done": {
        visibility: "hidden",
        transform: "scale(0)"
      }
    },
    Message: {
      marginRight: 20,
      lineHeight: snackBarHeight
    },
    Button: {
      display: "inline-block",
      marginTop: -4,
      padding: 0,
      border: 0,
      background: "none",
      fontSize: snackBarButtonSize,
      lineHeight: snackBarButtonSize,
      color: "inherit",
      cursor: "pointer",
      appearance: "none",
      verticalAlign: "middle",
      "& svg": {
        verticalAlign: "top"
      }
    },
    error: {
      backgroundColor: theme.colors.error,
    },
    success: {
      backgroundColor: theme.colors.success,
      color: theme.colors.text
    },
  };
});

const Snackbar = createContext({});

function Provider({ children }) {
  const classes = styles();
  const allSnacks = React.useRef([]);
  const [snacks, setSnacks] = useState([]);
  const [timeouts, setTimeouts] = useState([]);
  function close(snack) {
    if (allSnacks.current.indexOf(snack) >= 0) {
      allSnacks.current[allSnacks.current.indexOf(snack)].visible = false;
      updateState();
    }
  }
  function remove(snack) {
    if (allSnacks.current.indexOf(snack) >= 0) {
      allSnacks.current.splice(allSnacks.current.indexOf(snack), 1);
      updateState();
    }
  }
  function updateState() {
    setSnacks(allSnacks.current.slice());
  }
  useEffect(() => {
    setTimeouts(snacks.map(snack => setTimeout(() => close(snack), snack.timeout)));
    return () => {
      timeouts.map(clearTimeout);
    };
  }, [snacks])
  return (
    <Snackbar.Provider value={{
      addSnack: (...newSnacks) => {
        newSnacks.map(snack => {
          if (allSnacks.current.find(s => Map(snack).equals(Map(s.originalRef)))) return;
          snack = Object.assign({}, {
            timeout: 5000,
            closable: true,
            originalRef: snack
          }, snack);
          snack.visible = true;
          allSnacks.current.push(snack);
        })
        updateState();
      }
    }}>
      {children}
      {snacks.length > 0 && (
        <div className={classes.Root}>
          {snacks.map(snack => (
            <CSSTransition
              in={snack.visible}
              timeout={500}
              classNames="snack-bar"
              onExited={() => remove(snack)}
              unmountOnExit
              appear>
                <div className={cx(classes.Snack, classes[snack.type])}>
                  <span className={classes.Message}>{snack.message}</span>
                  {snack.closable && (
                    <button className={classes.Button} onClick={() => close(snack)}>
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                  )}
                </div>
            </CSSTransition>
          ))}
        </div>
      )}
    </Snackbar.Provider>
  )
}

export default {
  Context: Snackbar,
  Provider,
  Consumer: Snackbar.Consumer
}
