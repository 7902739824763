import React, { Component } from 'react';
import propTypes from 'prop-types';
import withStyles from 'react-jss';
import classNames from 'classnames';
import { Units } from '../utils';

const Styles = theme => ({
  Root: {
    display: 'inline-block',
    padding: ['.7em', '1.4em', '0.9em'],
    marginBottom: '.3em',
    borderRadius: '.2em',
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    boxShadow: `inset 0 -0.6em 0 -0.35em ${Units.rgba(
      theme.colors.dark,
      0.17,
    )}`,
    border: 'none',
  },
  button: {
    appearance: 'none',
  },
  full: {
    width: '100%',
  },
  color_default: {
    backgroundColor: '#ddd',
  },
  color_primary: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.light,
  },
  color_secondary: {
    backgroundColor: theme.colors.secondary,
    color: theme.colors.light,
  },
  color_light: {
    backgroundColor: theme.colors.light,
    color: theme.colors.dark,
  },
  color_dark: {
    backgroundColor: theme.colors.dark,
    color: theme.colors.light,
  },
});

const Button = props => {
  const { comp, children, className, color, full, classes, ...rest } = props;
  if (comp === 'button' && !rest.type) {
    rest.type = 'button';
  }
  rest.className = classNames(
    classes.Root,
    classes[comp.displayName || comp],
    classes[`color_${color}`],
    full && classes.full,
    className,
  );
  return React.createElement(comp, rest, children);
};

Button.defaultProps = {
  comp: 'button',
  color: 'default',
  full: false,
  classes: {},
};

Button.propTypes = {
  comp: propTypes.oneOfType([propTypes.string, propTypes.shape(Component)])
    .isRequired,
  color: propTypes.oneOf(['default', 'primary', 'secondary', 'light', 'dark'])
    .isRequired,
  full: propTypes.bool.isRequired,
  classes: propTypes.object.isRequired,
  className: propTypes.string,
};

export default withStyles(Styles)(Button);
