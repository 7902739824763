import React from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare as farFaSquare, faMinus } from "@fortawesome/pro-regular-svg-icons";
import { faSquare as fasFaSquare } from "@fortawesome/pro-solid-svg-icons";

const styles = createUseStyles({
  Input: {
    position: 'absolute',
    opacity: 0,
  },
  Icon: {
    marginRight: "1em"
  }
})

function Checkbox({ children, onChange, checked, indeterminate, inputProps = {}, ...props }) {
  const classes = styles();
  return (
    <label {...props}>
      <input
        {...inputProps}
        className={cx(inputProps.className, classes.Input)}
        type="checkbox"
        checked={checked}
        indeterminate={indeterminate}
        onChange={event => onChange(event.target.checked, indeterminate, event)}
      />
      <span className={classes.Icon}>
        {indeterminate ? (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={fasFaSquare} />
          <FontAwesomeIcon icon={faMinus} inverse />
        </span>
        ) : (
          <FontAwesomeIcon icon={checked ? faCheckSquare : farFaSquare} />
        )}
      </span>
      {children}
    </label>
  );
}

export default Checkbox;
