import React from 'react';
import withStyles from 'react-jss';
import classNames from 'classnames';

const Styles = {
  Root: {
    width: '100%',
    backgroundImage: 'inherit',
    backgroundAttachment: 'fixed',
    position: 'relative',
    zIndex: '1',
    borderRadius: 6,
    padding: 20,
    overflow: 'hidden',
    '&:before': {
      content: '""',
      backgroundImage: 'inherit',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      position: 'absolute',
      zIndex: '-1',
      top: -20,
      left: -20,
      right: -20,
      bottom: -20,
      boxShadow: 'inset 0 0 2000px rgba(255, 255, 255, .5)',
      filter: 'blur(10px)',
    },
  },
};

const Panel = function(props) {
  const { classes, className, ...rest } = props;
  return <div {...rest} className={classNames(classes.Root, className)} />;
};
export default withStyles(Styles)(Panel);
