import React from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import Fade from "../partials/animations/Fade";
import Spinner from "../partials/Spinner";

const useStyles = createUseStyles({
  Loader: {
    textAlign: "center",
  }
})

export default function Loader({ size, ...props }) {
  const classes = useStyles();
  return (
    <Fade {...props} className={cx(classes.Loader, props.className)} >
      <Spinner size={size} />
    </Fade>
  );
}
